import {
  Checkbox,
  Group,
  HoverCard,
  Rating,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { IconLayoutList } from "@tabler/icons-react";
import useWindowSize from "hooks/useWindowSize";
import { xor } from "lodash";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBoard } from "routes/Home/Boards/Board/Provider";
import { useBoards } from "routes/Home/Boards/Provider";

const Title: FC = () => {
  const { board, boards: currentBoards = [], setExtraBoardIds } = useBoard();
  const { boards = [] } = useBoards();
  const { boardId } = useParams();
  const { largerThan } = useWindowSize();
  const navigate = useNavigate();

  const title = useMemo(() => {
    return (
      <Group spacing="xs">
        {largerThan("md") ? <IconLayoutList size={18} /> : null}
        <Text
          weight={500}
          className="max-w-xs overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {board?.name}
        </Text>
      </Group>
    );
  }, [board?.name, largerThan]);

  if (!boards.length) {
    return title;
  }

  return (
    <HoverCard shadow="md" width={200} withinPortal position="bottom-start">
      <HoverCard.Target>
        <div>
          {currentBoards.length} board{currentBoards.length > 1 ? "s" : ""}
        </div>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <SimpleGrid cols={1}>
          {boards
            .sort((a, b) => {
              if (a.id === boardId) {
                return -1;
              }

              if (b.id === boardId) {
                return 1;
              }

              if (a.name && b.name) {
                return a.name.localeCompare(b.name);
              }

              return 0;
            })
            .map((board) => (
              <Group key={board.id} spacing="xs">
                <Rating
                  count={1}
                  value={board.id === boardId ? 1 : 0}
                  onChange={() => {
                    navigate(`../${board.id}`);
                  }}
                />
                <Checkbox
                  disabled={board.id === boardId}
                  checked={Boolean(
                    currentBoards?.find(
                      (currentBoard) => currentBoard.id === board.id
                    )
                  )}
                  onChange={() => {
                    if (board.id) {
                      setExtraBoardIds(
                        (old) => xor(old, [board.id]) as string[]
                      );
                    }
                  }}
                  label={board.name}
                />
              </Group>
            ))}
        </SimpleGrid>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export default Title;
